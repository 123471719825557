$c-primary: #FF7800;
$c-secandary: #FF7800;
$dots: false;
$version: 3.1;
$heading-color-overrided: #12134B;
$body-color-overrided: #12134B;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Poppins', sans-serif;
$font-family-heading: 'Poppins', sans-serif;

      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.description {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.instructionList {
  margin-top: 30px;
}

.item {
  margin-bottom: 20px;
}

.instructionNumber {
  display: table-cell;
  width: 22px;
  height: 100%;
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;

  color: #000;
}

.instructionText {
  display: table-cell;
  height: 100%;
  margin-left: 10px;
}

.hiddenInput {
  display: none;
}

.instructionList {
  a.linkToSNSW {
    border: 1px solid var(--primary);
    background: var(--primary);
    font-family: var(--base-font-family);
    display: inline-block;
    color: white !important;
    height: 2.4rem;
    font-size: 1rem; //16
    border-radius: 0.25rem; //4
    padding: 0.5rem 1rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    user-select: none;
    font-weight: 500;
    border-radius: 0.625rem; //10
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}
