$c-primary: #FF7800;
$c-secandary: #FF7800;
$dots: false;
$version: 3.1;
$heading-color-overrided: #12134B;
$body-color-overrided: #12134B;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Poppins', sans-serif;
$font-family-heading: 'Poppins', sans-serif;

      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;

  .buttons {
    display: flex;
    justify-content: space-between;
    height: 100%;

    button {
      width: auto;
      height: 100%;
      min-width: 57%;
      white-space: break-spaces;
      line-height: 1;

      &:first-child {
        text-align: left;
        min-width: min-content !important;
      }

      + button {
        margin-left: .5rem;
      }
    }
  }

  .btnFull {
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      height: 100%;
    }
  }
}
