$c-primary: #FF7800;
$c-secandary: #FF7800;
$dots: false;
$version: 3.1;
$heading-color-overrided: #12134B;
$body-color-overrided: #12134B;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Poppins', sans-serif;
$font-family-heading: 'Poppins', sans-serif;

      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/assets-mobile/fonts/Poppins-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.Select {
  position: relative;
  padding: 0;
  .hasError {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 3px;
    color: $c-danger;
  }
}

.control {
  align-items: center;
  display: flex;
  min-height: 3rem;
  outline: none;
  background-color: #F4F4F4;
  border-radius: 0.4375rem;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  border: transparent;
  padding: 0.5rem 0.7rem;
  color: #394448;

  input {
    width: 100%;
    border: none;
    background: transparent;
  }

  button {
    border: none;
    background: transparent;
    padding: 8px;
    img {
      width: 1.5rem;
    }
  }
}

.selectedImageContainer {
  display: inline-block;
  margin-right: 0.625rem;

  &, img {
    width: 2rem;
    height: 1.25rem;
  }
}

.menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 100%;
  background: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.11);
  padding: 0;
  margin: 0;
  border: 1px solid #dadada;
  max-height: 350px;
  overflow-y: scroll;
}

.countryItem {
  list-style: none;
  padding: 0.8125rem;
  border-bottom: 1px solid #E8E8EC;

  &:last-child {
    border-bottom: none;
  }

  .imageContainer {
    margin-right: 0.625rem;
    .flagImage {
      width: 2rem;
      height: 1.125rem;
    }
  }
}

.hidden {
  display: none;
}

:export {
  primary: var(--primary);
  secondary: var(--secondary);
  primary25: '#fff';
  primary50: lighten($c-secandary, 45%);
  gray: $c-grey;
  bodyGray: #fff;
}
